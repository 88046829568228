/**
 * For calculations
 */
/**
 * Colors
 */
/**
 * Grid
 */
/**
 * Types
 */
/**
 * Spacing
 */
/**
 * Timing
 */
.mc.w-940 {
  max-width: 940px;
  width: 940px;
  margin-left: -16%;
}
.mc.w-940-full {
  margin: 30px calc((100vw - ( 720px - 30px )) / 2 * -1) 0;
  max-width: none;
  width: auto;
}
.mc.w-940-full .rp img {
  width: 100%;
}

#quick-access a {
  transition: opacity 0.2s;
}
#quick-access a:hover, #quick-access a:focus {
  opacity: 0.64;
}

#access .around-here {
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px;
}
#access .around-here .previous,
#access .around-here .next {
  flex: 1 1 20%;
  order: 0;
  padding-left: 15px;
  padding-right: 15px;
}
#access .around-here .next {
  text-align: right;
  order: 2;
}
#access .around-here #toc {
  flex: 1 1 60%;
  order: 1;
  padding-left: 30px;
  padding-right: 15px;
}

.short-series .mc.w-940 {
  margin-left: -18%;
}
.short-series .mc.w-940-full {
  margin: 30px calc((100vw - ( 720px - 30px )) / 2 * -1) 0;
}
.short-series .mc.w-700 {
  margin-left: -5px;
  margin-right: -5px;
}

.intro {
  margin-bottom: 64px;
}

.entries ol.row {
  margin-top: 64px;
}
.entries .title,
.entries .content {
  transition: all 0.2s;
  transform: translate(0, 0);
}
.entries li.featured .title {
  font-size: 1.8rem;
}

.carousel,
.carousel .flickity-viewport {
  max-height: 768px;
}

.jumbotron.slider .mc.w-940-full {
  margin: 0;
  width: 100%;
}

.carousel.flickity-enabled .mc:not(.slide-main) .legende {
  opacity: 1;
}

.carousel .mc.slide-main h1 {
  height: 60px;
}

.carousel .mc.slide-hawaii-ep5 .photo:after {
  content: "";
  opacity: 0.4;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent !important;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000 100%) !important;
}
.carousel .mc.slide-hawaii-ep5 .legende {
  background: transparent;
  bottom: 0;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.carousel .mc.slide-hawaii-ep5 h2 {
  font-size: 2.275rem;
  font-size: 3.7vw;
  color: #fff;
}
.carousel .mc.slide-hawaii-ep5 p {
  color: #fff;
}
.carousel .mc.slide-hawaii-ep5 a {
  display: inline-block;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 32px;
}
.carousel .mc.slide-hawaii-ep5 a:hover {
  padding-left: 48px;
  padding-right: 48px;
}

.carousel .mc.slide-hawaii h2, .carousel .mc.slide-hawaii .legende {
  color: #212529;
}
.carousel .mc.slide-hawaii .photo:after {
  content: "";
  opacity: 0.4;
  position: absolute;
  top: -2.6875rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent !important;
  background-image: linear-gradient(to right, rgba(236, 241, 243, 0) 0%, #ecf1f3 100%) !important;
  background-repeat: repeat-x;
}
.carousel .mc.slide-hawaii .legende {
  background: transparent;
  top: 0;
  bottom: 0;
  right: 5%;
  left: auto;
  width: 40%;
  transform: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.carousel .mc.slide-hawaii h2 {
  font-size: 2.275rem;
  font-size: 3.7vw;
}
.carousel .mc.slide-hawaii a {
  display: inline-block;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 32px;
}
.carousel .mc.slide-hawaii a:hover {
  padding-left: 48px;
  padding-right: 48px;
}

.carousel .mc.slide-shades-of-white .photo:after {
  content: "";
  opacity: 0.4;
  position: absolute;
  top: -2.6875rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent !important;
  background-image: linear-gradient(to bottom left, rgba(234, 239, 239, 0) 0%, #eaefef 100%) !important;
  background-repeat: repeat-x;
}
.carousel .mc.slide-shades-of-white h2 {
  font-size: 2.275rem;
  font-size: 3.7vw;
  color: #213b4c;
}
.carousel .mc.slide-shades-of-white p {
  color: #213b4c;
}
.carousel .mc.slide-shades-of-white .legende {
  left: 36%;
  bottom: 8%;
  background: transparent;
}
.carousel .mc.slide-shades-of-white a {
  display: inline-block;
  padding-left: 32px;
  padding-right: 32px;
  margin-top: 32px;
}
.carousel .mc.slide-shades-of-white a:hover {
  padding-left: 48px;
  padding-right: 48px;
}
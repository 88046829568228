.mc {

  &.w-940 {
    max-width: 940px;
    width: 940px;
    margin-left: -16%;
  }

  &.w-940-full {
    margin: ( $grid-gutter-width ) ( calc( ( 100vw - ( #{map-get( $container-max-widths, lg )} - #{$grid-gutter-width} ) ) / 2 * -1 ) ) 0;
    max-width: none;
    width: auto;

    .rp img {
      width: 100%;
    }
  }

}

#quick-access {
  a {
    transition: opacity $transition-duration;
    &:hover, &:focus {
      opacity: .64;
    }
  }
}

#access .around-here {
  flex-direction: row;
  margin-left: math.div( -$grid-gutter-width, 2 );
  margin-right: math.div( -$grid-gutter-width, 2 );

  .previous,
  .next {
    flex: 1 1 20%;
    order: 0;
    padding-left: math.div( $grid-gutter-width, 2 );
    padding-right: math.div( $grid-gutter-width, 2 );
  }
  .next {
    text-align: right;
    order: 2;
  }
  #toc {
    flex: 1 1 60%;
    order: 1;
    padding-left: $grid-gutter-width;
    padding-right: math.div( $grid-gutter-width, 2 );
  }
}

.short-series {
  .mc {
    &.w-940 {
      margin-left: -18%;
    }
    &.w-940-full {
      margin: ( $grid-gutter-width ) ( calc( ( 100vw - ( #{map-get( $container-max-widths, lg )} - #{$grid-gutter-width} ) ) / 2 * -1 ) ) 0;
    }
    &.w-700 {
      margin-left: -5px;
      margin-right: -5px;
    }
  }
}

// Content

.intro {
  margin-bottom: $space-8;
}

.entries {

  ol.row {
    margin-top: $space-8;
  }

  .title,
  .content {
    transition: all $transition-duration;
    transform: translate( 0, 0 );
  }

  li.featured {
    .title {
      font-size: $h4-font-size * 1.2;
    }
  }

}

//

.carousel {
  &,
  .flickity-viewport {
    max-height: $space-16;
  }
}

.jumbotron.slider {
  .mc.w-940-full {
    margin: 0;
    width: 100%;
  }
}

.carousel.flickity-enabled {
  .mc:not( .slide-main ) {
    .legende {
      opacity: 1;
    }
  }
}
.carousel .mc.slide-main {
  h1 {
    height: 60px;
  }
}
.carousel .mc.slide-hawaii-ep5 {
  .photo:after {
    content: '';
    opacity: .4;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent !important;
    background-image: linear-gradient( to bottom, rgba( #000, 0 ) 0%, #000 100% ) !important;
  }
  .legende {
    background: transparent;
    // top: 0;
    bottom: 0;
    width: 40%;
    // transform: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

  }
  h2 {
    font-size: $h1-font-size-lg;
    font-size: calc( .037 * 100vw );
    color: #fff;
  }
  p {
    color: #fff;
  }
  a {
    display: inline-block;
    padding-left: $space-6;
    padding-right: $space-6;
    margin-top: $space-6;

    &:hover {
      padding-left: $space-7;
      padding-right: $space-7;
    }
  }
}
.carousel .mc.slide-hawaii {
  h2, .legende {
    color: $body-color;
  }
  .photo:after {
    content: '';
    opacity: .4;
    position: absolute;
    top: -2.6875rem;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent !important;
    background-image: linear-gradient( to right, rgba( #ecf1f3, 0 ) 0%, #ecf1f3 100% ) !important;
    background-repeat: repeat-x;
  }
  .legende {
    background: transparent;
    top: 0;
    bottom: 0;
    right: 5%;
    left: auto;
    width: 40%;
    transform: none;
    display: flex;
    flex-direction: column;
    justify-content: center;

  }
  h2 {
    font-size: $h1-font-size-lg;
    font-size: calc( .037 * 100vw );
  }
  a {
    display: inline-block;
    padding-left: $space-6;
    padding-right: $space-6;
    margin-top: $space-6;

    &:hover {
      padding-left: $space-7;
      padding-right: $space-7;
    }
  }
}
.carousel .mc.slide-shades-of-white {
  .photo:after {
    content: '';
    opacity: .4;
    position: absolute;
    top: -2.6875rem;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent !important;
    background-image: linear-gradient( to bottom left, rgba( #eaefef, 0 ) 0%, #eaefef 100% ) !important;
    background-repeat: repeat-x;
  }
  h2 {
    font-size: $h1-font-size-lg;
    font-size: calc( .037 * 100vw );
    color: #213b4c;
  }
  p {
    color: #213b4c;
  }
  .legende {
    left: 36%;
    bottom: 8%;
    background: transparent;
  }
  a {
    display: inline-block;
    padding-left: $space-6;
    padding-right: $space-6;
    margin-top: $space-6;

    &:hover {
      padding-left: $space-7;
      padding-right: $space-7;
    }
  }
}
